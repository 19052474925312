<template>
  <vuestic-sidebar :hidden="!isOpen">
    <template slot="menu">
      <sidebar-link :to="{ name: 'dashboard' }">
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M23.9,11.437A12,12,0,0,0,0,13a11.878,11.878,0,0,0,3.759,8.712A4.84,4.84,0,0,0,7.113,23H16.88a4.994,4.994,0,0,0,3.509-1.429A11.944,11.944,0,0,0,23.9,11.437Zm-4.909,8.7A3,3,0,0,1,16.88,21H7.113a2.862,2.862,0,0,1-1.981-.741A9.9,9.9,0,0,1,2,13,10.014,10.014,0,0,1,5.338,5.543,9.881,9.881,0,0,1,11.986,3a10.553,10.553,0,0,1,1.174.066,9.994,9.994,0,0,1,5.831,17.076ZM7.807,17.285a1,1,0,0,1-1.4,1.43A8,8,0,0,1,12,5a8.072,8.072,0,0,1,1.143.081,1,1,0,0,1,.847,1.133.989.989,0,0,1-1.133.848,6,6,0,0,0-5.05,10.223Zm12.112-5.428A8.072,8.072,0,0,1,20,13a7.931,7.931,0,0,1-2.408,5.716,1,1,0,0,1-1.4-1.432,5.98,5.98,0,0,0,1.744-5.141,1,1,0,0,1,1.981-.286Zm-5.993.631a2.033,2.033,0,1,1-1.414-1.414l3.781-3.781a1,1,0,1,1,1.414,1.414Z"
              />
            </svg>
          </span>
          <span class="sidebar-menu-item-text">{{
            $t('sidebar.dashboard')
          }}</span>
        </span>
      </sidebar-link>
      <sidebar-link :to="{ name: 'dids' }">
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M13,1a1,1,0,0,1,1-1A10.011,10.011,0,0,1,24,10a1,1,0,0,1-2,0,8.009,8.009,0,0,0-8-8A1,1,0,0,1,13,1Zm1,5a4,4,0,0,1,4,4,1,1,0,0,0,2,0,6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2Zm9.093,10.739a3.1,3.1,0,0,1,0,4.378l-.91,1.049c-8.19,7.841-28.12-12.084-20.4-20.3l1.15-1A3.081,3.081,0,0,1,7.26.906c.031.031,1.884,2.438,1.884,2.438a3.1,3.1,0,0,1-.007,4.282L7.979,9.082a12.781,12.781,0,0,0,6.931,6.945l1.465-1.165a3.1,3.1,0,0,1,4.281-.006S23.062,16.708,23.093,16.739Zm-1.376,1.454s-2.393-1.841-2.424-1.872a1.1,1.1,0,0,0-1.549,0c-.027.028-2.044,1.635-2.044,1.635a1,1,0,0,1-.979.152A15.009,15.009,0,0,1,5.9,9.3a1,1,0,0,1,.145-1S7.652,6.282,7.679,6.256a1.1,1.1,0,0,0,0-1.549c-.031-.03-1.872-2.425-1.872-2.425a1.1,1.1,0,0,0-1.51.039l-1.15,1C-2.495,10.105,14.776,26.418,20.721,20.8l.911-1.05A1.121,1.121,0,0,0,21.717,18.193Z"
              />
            </svg>
          </span>
          <span>{{ $t('sidebar.did') }}</span>
        </span>
      </sidebar-link>
      <!--  -->
      <sidebar-link-group v-if="!!currentTenant && !!currentTenant.hasTenant">
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm6,13A6,6,0,0,0,6,23a1,1,0,0,0,2,0,4,4,0,0,1,8,0,1,1,0,0,0,2,0ZM18,8a4,4,0,1,1,4-4A4,4,0,0,1,18,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,18,2Zm6,13a6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0ZM6,8a4,4,0,1,1,4-4A4,4,0,0,1,6,8ZM6,2A2,2,0,1,0,8,4,2,2,0,0,0,6,2ZM2,15a4,4,0,0,1,4-4A1,1,0,0,0,6,9a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0Z"
              />
            </svg>
          </span>
          <span class="sidebar-menu-item-text">{{
            $t('sidebar.extensions.title')
          }}</span>
        </span>
        <sidebar-link :to="{ name: 'all-extensions' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image"> </span>
            <span class="sidebar-menu-item-text">{{
              $t('sidebar.extensions.list')
            }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'monitor-extensions' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image"> </span>
            <span class="sidebar-menu-item-text">{{
              $t('sidebar.extensions.monitor')
            }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
      <!--  -->

      <sidebar-link
        :to="{ name: 'call-reporting' }"
        v-if="!!currentTenant && !!currentTenant.hasTenant"
      >
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="m6.5,16H1C.448,16,0,15.552,0,15s.448-1,1-1h4.965l1.703-2.555c.197-.296.542-.473.894-.443.356.022.673.232.833.551l2.229,4.459,1.044-1.566c.186-.278.498-.445.832-.445h4.5c.552,0,1,.448,1,1s-.448,1-1,1h-3.965l-1.703,2.555c-.186.279-.499.445-.832.445-.021,0-.042,0-.062-.002-.356-.022-.673-.232-.833-.551l-2.229-4.459-1.044,1.566c-.186.278-.498.445-.832.445Zm15.5-5.515v8.515c0,2.757-2.243,5-5,5H7c-2.757,0-5-2.243-5-5,0-.552.448-1,1-1s1,.448,1,1c0,1.654,1.346,3,3,3h10c1.654,0,3-1.346,3-3v-8.515c0-.163-.008-.325-.023-.485h-4.977c-1.654,0-3-1.346-3-3V2.023c-.16-.015-.322-.023-.485-.023h-4.515c-1.654,0-3,1.346-3,3v6c0,.552-.448,1-1,1s-1-.448-1-1v-6C2,2.243,4.243,0,7,0h4.515c1.87,0,3.627.728,4.95,2.05l3.485,3.485c1.322,1.322,2.05,3.08,2.05,4.95Zm-2.659-2.485c-.218-.379-.487-.733-.805-1.05l-3.485-3.485c-.318-.318-.671-.587-1.05-.805v4.341c0,.551.449,1,1,1h4.341Z"
              />
            </svg>
          </span>
          <span>{{ $t('sidebar.call-reporting') }}</span>
        </span>
      </sidebar-link>
      <sidebar-link-group>
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M16,23c0,.552-.447,1-1,1H6c-2.757,0-5-2.243-5-5V5C1,2.243,3.243,0,6,0h4.515c1.869,0,3.627,.728,4.95,2.05l3.484,3.486c.271,.271,.523,.568,.748,.883,.321,.449,.217,1.074-.232,1.395-.449,.32-1.075,.217-1.395-.233-.161-.225-.341-.438-.534-.63l-3.485-3.486c-.318-.318-.671-.587-1.051-.805V7c0,.551,.448,1,1,1h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-1.654,0-3-1.346-3-3V2.023c-.16-.015-.322-.023-.485-.023H6c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3H15c.553,0,1,.448,1,1Zm5.685-6.733l-3.041-.507c-.373-.062-.644-.382-.644-.76,0-.551,.448-1,1-1h2.268c.356,0,.688,.192,.867,.5,.275,.478,.885,.641,1.366,.365,.478-.277,.642-.888,.364-1.366-.534-.925-1.53-1.5-2.598-1.5h-.268v-1c0-.552-.447-1-1-1s-1,.448-1,1v1c-1.654,0-3,1.346-3,3,0,1.36,.974,2.51,2.315,2.733l3.041,.507c.373,.062,.644,.382,.644,.76,0,.551-.448,1-1,1h-2.268c-.356,0-.688-.192-.867-.5-.275-.479-.886-.642-1.366-.365-.478,.277-.642,.888-.364,1.366,.534,.925,1.53,1.499,2.598,1.499h.268v1c0,.552,.447,1,1,1s1-.448,1-1v-1c1.654,0,3-1.346,3-3,0-1.36-.974-2.51-2.315-2.733Zm-14.185-1.267h5.5c.553,0,1-.448,1-1s-.447-1-1-1H7.5c-1.378,0-2.5,1.122-2.5,2.5v2c0,1.378,1.122,2.5,2.5,2.5h5.5c.553,0,1-.448,1-1s-.447-1-1-1H7.5c-.276,0-.5-.224-.5-.5v-2c0-.276,.224-.5,.5-.5Zm-1.5-4h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1s.448,1,1,1Zm0-4h2c.552,0,1-.448,1-1s-.448-1-1-1h-2c-.552,0-1,.448-1,1s.448,1,1,1Z"
              />
            </svg>
          </span>
          <span class="sidebar-menu-item-text">{{
            $t('sidebar.billing.title')
          }}</span>
        </span>
        <sidebar-link :to="{ name: 'billing-charges' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Outline"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M16,0H8A5.006,5.006,0,0,0,3,5V23a1,1,0,0,0,1.564.825L6.67,22.386l2.106,1.439a1,1,0,0,0,1.13,0l2.1-1.439,2.1,1.439a1,1,0,0,0,1.131,0l2.1-1.438,2.1,1.437A1,1,0,0,0,21,23V5A5.006,5.006,0,0,0,16,0Zm3,21.1-1.1-.752a1,1,0,0,0-1.132,0l-2.1,1.439-2.1-1.439a1,1,0,0,0-1.131,0l-2.1,1.439-2.1-1.439a1,1,0,0,0-1.129,0L5,21.1V5A3,3,0,0,1,8,2h8a3,3,0,0,1,3,3Z"
                />
                <rect x="7" y="8" width="10" height="2" rx="1" />
                <rect x="7" y="12" width="8" height="2" rx="1" />
              </svg>
            </span>
            <span class="sidebar-menu-item-text">{{
              $t('sidebar.billing.charges')
            }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'billing-payments' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M19,4h-1.1c-.46-2.28-2.48-4-4.9-4H5C2.24,0,0,2.24,0,5v12.86c0,.79,.43,1.52,1.13,1.89,.32,.17,.67,.25,1.01,.25,.42,0,.83-.12,1.19-.36l2.97-1.98c.69,1.94,2.52,3.34,4.69,3.34h5.7l3.96,2.64c.36,.24,.77,.36,1.19,.36,.35,0,.7-.08,1.01-.25,.7-.37,1.13-1.1,1.13-1.89V9c0-2.76-2.24-5-5-5ZM2.23,17.98s-.07,.05-.15,0c-.08-.04-.08-.11-.08-.13V5c0-1.65,1.35-3,3-3H13c1.65,0,3,1.35,3,3v7c0,1.65-1.35,3-3,3H7c-.2,0-.39,.06-.55,.17l-4.21,2.81Zm19.77,3.88s0,.09-.08,.13c-.08,.04-.13,0-.15,0l-4.21-2.81c-.16-.11-.36-.17-.55-.17h-6c-1.3,0-2.4-.84-2.82-2h4.82c2.76,0,5-2.24,5-5V6h1c1.65,0,3,1.35,3,3v12.86ZM7.73,13c-1.07,0-2.06-.57-2.6-1.5-.28-.48-.11-1.09,.36-1.37,.48-.28,1.09-.11,1.37,.36,.18,.31,.51,.5,.87,.5h2.64c.34,0,.62-.28,.62-.62,0-.31-.22-.57-.52-.62l-3.29-.55c-1.27-.21-2.19-1.3-2.19-2.59,0-1.45,1.18-2.62,2.62-2.62h.38c0-.55,.45-1,1-1s1,.45,1,1h.27c1.07,0,2.06,.57,2.6,1.5,.28,.48,.11,1.09-.36,1.37-.48,.28-1.09,.11-1.37-.36-.18-.31-.51-.5-.87-.5h-2.64c-.34,0-.62,.28-.62,.62,0,.31,.22,.56,.52,.62l3.28,.55c1.27,.21,2.19,1.3,2.19,2.59,0,1.45-1.18,2.62-2.62,2.62h-.38c0,.55-.45,1-1,1s-1-.45-1-1h-.27Z"
                />
              </svg>
            </span>
            <span>{{ $t('sidebar.billing.payments-history') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'billing-refill' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M23.02,8.79c-.59-.54-1.36-.81-2.17-.78-.8,.04-1.54,.39-2.09,.98l-3.22,3.53c-.55-.91-1.55-1.52-2.69-1.52h-3.86v-1h.38c1.45,0,2.62-1.18,2.62-2.62,0-1.29-.92-2.38-2.19-2.59l-3.29-.55c-.3-.05-.52-.31-.52-.62,0-.34,.28-.62,.62-.62h2.38c.55,0,1,.45,1,1h2c0-1.65-1.35-3-3-3V0h-2V1h-.38c-1.45,0-2.62,1.18-2.62,2.62,0,1.29,.92,2.38,2.19,2.59l3.29,.55c.3,.05,.52,.31,.52,.62,0,.34-.28,.62-.62,.62h-2.38c-.55,0-1-.45-1-1h-2c0,1.65,1.35,3,3,3v1H3c-1.65,0-3,1.35-3,3v7c0,1.65,1.35,3,3,3H13.45l9.79-10.99c1.09-1.23,.99-3.12-.22-4.23Zm-1.27,2.9l-9.19,10.32H3c-.55,0-1-.45-1-1v-7c0-.55,.45-1,1-1H12.86c.63,0,1.14,.51,1.14,1.14,0,.56-.42,1.05-.98,1.13l-5.16,.74,.28,1.98,5.16-.74c1.18-.17,2.13-.99,2.51-2.06l4.43-4.86c.18-.2,.43-.32,.7-.33,.27,0,.53,.08,.73,.26,.41,.37,.44,1.01,.07,1.42Z"
                />
              </svg>
            </span>
            <span>{{ $t('sidebar.billing.refill-balance') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'billing-rates' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12,12-5.383,12-12S18.617,0,12,0Zm0,22c-5.514,0-10-4.486-10-10S6.486,2,12,2s10,4.486,10,10-4.486,10-10,10Zm4-8c0,1.654-1.346,3-3,3v1c0,.553-.447,1-1,1s-1-.447-1-1v-1h-.268c-1.067,0-2.063-.574-2.598-1.499-.277-.479-.113-1.09,.364-1.366,.479-.279,1.091-.113,1.366,.364,.179,.31,.511,.501,.867,.501h2.268c.552,0,1-.448,1-1,0-.378-.271-.698-.644-.76l-3.041-.507c-1.342-.223-2.315-1.373-2.315-2.733,0-1.654,1.346-3,3-3v-1c0-.552,.447-1,1-1s1,.448,1,1v1h.268c1.067,0,2.063,.575,2.598,1.5,.277,.478,.113,1.089-.364,1.366-.48,.277-1.091,.113-1.366-.365-.179-.309-.511-.5-.867-.5h-2.268c-.552,0-1,.449-1,1,0,.378,.271,.698,.644,.76l3.041,.507c1.342,.223,2.315,1.373,2.315,2.733Z"
                />
              </svg>
            </span>
            <span class="sidebar-menu-item-text">{{
              $t('sidebar.billing.rates')
            }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'billing-invoices' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M12,16c-2.206,0-4-1.794-4-4s1.794-4,4-4,4,1.794,4,4-1.794,4-4,4Zm0-6c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm-7-3c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm13,3c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Zm-13,3c-.552,0-1,.448-1,1s.448,1,1,1,1-.448,1-1-.448-1-1-1Zm13,3c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Zm-1.001,5c-1.634,0-3.098-.399-4.513-.785-1.348-.368-2.62-.715-3.986-.715-1.571,0-2.562,.101-3.419,.349-1.206,.347-2.474,.113-3.48-.644-1.017-.765-1.6-1.933-1.6-3.205v-7.548c0-2.063,1.299-3.944,3.233-4.681,1.341-.512,2.609-.771,3.768-.771,1.634,0,3.097,.399,4.513,.785,1.348,.368,2.62,.715,3.986,.715,1.57,0,2.562-.101,3.419-.349,1.208-.347,2.476-.113,3.481,.644,1.017,.765,1.6,1.933,1.6,3.205v7.548h0c0,2.063-1.3,3.944-3.234,4.681-1.341,.512-2.608,.771-3.768,.771Zm-8.499-3.5c1.634,0,3.097,.399,4.513,.785,1.348,.368,2.62,.715,3.986,.715,.914,0,1.942-.215,3.056-.64,1.183-.45,1.946-1.554,1.946-2.812v-7.548c0-.637-.293-1.223-.803-1.606-.499-.375-1.126-.493-1.725-.321-1.051,.303-2.202,.427-3.974,.427-1.634,0-3.097-.399-4.513-.785-1.348-.368-2.62-.715-3.986-.715-.915,0-1.942,.215-3.056,.64-1.183,.45-1.946,1.554-1.946,2.812v7.548c0,.637,.293,1.223,.803,1.606,.499,.375,1.126,.493,1.724,.32,1.051-.303,2.203-.427,3.974-.427Z"
                />
              </svg>
            </span>
            <span class="sidebar-menu-item-text">{{
              $t('sidebar.billing.invoices')
            }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>

      <sidebar-link-group>
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.261 1.03462C12.6971 1.15253 13 1.54819 13 1.99997V8.99997H19C19.3581 8.99997 19.6888 9.19141 19.8671 9.50191C20.0455 9.8124 20.0442 10.1945 19.8638 10.5038L12.8638 22.5038C12.6361 22.8941 12.1751 23.0832 11.739 22.9653C11.3029 22.8474 11 22.4517 11 22V15H5C4.64193 15 4.3112 14.8085 4.13286 14.498C3.95452 14.1875 3.9558 13.8054 4.13622 13.4961L11.1362 1.4961C11.3639 1.10586 11.8249 0.916719 12.261 1.03462ZM6.74104 13H12C12.5523 13 13 13.4477 13 14V18.301L17.259 11H12C11.4477 11 11 10.5523 11 9.99997V5.69889L6.74104 13Z"
              />
            </svg>
          </span>
          <span class="sidebar-menu-item-text">{{
            $t('sidebar.usage.title')
          }}</span>
        </span>
        <sidebar-link :to="{ name: 'usage-summary' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M10 14H14M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="sidebar-menu-item-text">{{
              $t('sidebar.usage.summary')
            }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'usage-detailed' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H12M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19M19 9V14.5M9 17H11.5M9 13H15M9 9H10M15.5 18.5H20.5"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="sidebar-menu-item-text">{{
              $t('sidebar.usage.detailed')
            }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>

      <sidebar-link-group>
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Outline"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354A2.994,2.994,0,0,0,22.713,4.077ZM21.4,6.178l-.786,4.354A3,3,0,0,1,17.657,13H5.419L4.478,5H20.41A1,1,0,0,1,21.4,6.178Z"
              />
              <circle cx="7" cy="22" r="2" />
              <circle cx="17" cy="22" r="2" />
            </svg>
          </span>
          <span>{{ $t('sidebar.order-confirmation.title') }}</span>
        </span>
        <sidebar-link :to="{ name: 'order-confirmation-flows-list' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M24,4c0-2.206-1.794-4-4-4s-4,1.794-4,4c0,1.86,1.277,3.428,3,3.873v.127c0,1.654-1.346,3-3,3h-1c-1.193,0-2.267,.525-3,1.357-.733-.832-1.807-1.357-3-1.357h-1c-1.654,0-3-1.346-3-3v-.127c1.723-.445,3-2.013,3-3.873C8,1.794,6.206,0,4,0S0,1.794,0,4c0,1.86,1.277,3.428,3,3.873v.127c0,2.757,2.243,5,5,5h1c1.103,0,2,.897,2,2v1.127c-1.723,.445-3,2.013-3,3.873,0,2.206,1.794,4,4,4s4-1.794,4-4c0-1.86-1.277-3.428-3-3.873v-1.127c0-1.103,.897-2,2-2h1c2.757,0,5-2.243,5-5v-.127c1.723-.445,3-2.013,3-3.873ZM2,4c0-1.103,.897-2,2-2s2,.897,2,2-.897,2-2,2-2-.897-2-2ZM14,20c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2,2,.897,2,2Zm6-14c-1.103,0-2-.897-2-2s.897-2,2-2,2,.897,2,2-.897,2-2,2Z"
                />
              </svg>
            </span>
            <span>{{ $t('sidebar.order-confirmation.flows') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'order-confirmation-calls' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M13,1a1,1,0,0,1,1-1A10.011,10.011,0,0,1,24,10a1,1,0,0,1-2,0,8.009,8.009,0,0,0-8-8A1,1,0,0,1,13,1Zm1,5a4,4,0,0,1,4,4,1,1,0,0,0,2,0,6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2Zm9.093,10.739a3.1,3.1,0,0,1,0,4.378l-.91,1.049c-8.19,7.841-28.12-12.084-20.4-20.3l1.15-1A3.081,3.081,0,0,1,7.26.906c.031.031,1.884,2.438,1.884,2.438a3.1,3.1,0,0,1-.007,4.282L7.979,9.082a12.781,12.781,0,0,0,6.931,6.945l1.465-1.165a3.1,3.1,0,0,1,4.281-.006S23.062,16.708,23.093,16.739Zm-1.376,1.454s-2.393-1.841-2.424-1.872a1.1,1.1,0,0,0-1.549,0c-.027.028-2.044,1.635-2.044,1.635a1,1,0,0,1-.979.152A15.009,15.009,0,0,1,5.9,9.3a1,1,0,0,1,.145-1S7.652,6.282,7.679,6.256a1.1,1.1,0,0,0,0-1.549c-.031-.03-1.872-2.425-1.872-2.425a1.1,1.1,0,0,0-1.51.039l-1.15,1C-2.495,10.105,14.776,26.418,20.721,20.8l.911-1.05A1.121,1.121,0,0,0,21.717,18.193Z"
                />
              </svg>
            </span>
            <span>{{ $t('sidebar.order-confirmation.calls') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
      <sidebar-link-group>
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              id="Layer_1"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
            >
              <path
                d="m17 0a1 1 0 0 0 -1 1c0 2.949-2.583 4-5 4h-7a4 4 0 0 0 -4 4v2a3.979 3.979 0 0 0 1.514 3.109l3.572 7.972a3.233 3.233 0 0 0 2.953 1.919 2.982 2.982 0 0 0 2.72-4.2l-2.2-4.8h2.441c2.417 0 5 1.051 5 4a1 1 0 0 0 2 0v-18a1 1 0 0 0 -1-1zm-8.063 20.619a.983.983 0 0 1 -.898 1.381 1.232 1.232 0 0 1 -1.126-.734l-2.808-6.266h2.254zm7.063-6.019a7.723 7.723 0 0 0 -5-1.6h-7a2 2 0 0 1 -2-2v-2a2 2 0 0 1 2-2h7a7.723 7.723 0 0 0 5-1.595zm7.9.852a1 1 0 0 1 -1.342.448l-2-1a1 1 0 0 1 .894-1.79l2 1a1 1 0 0 1 .448 1.337zm-3.79-9a1 1 0 0 1 .448-1.342l2-1a1 1 0 1 1 .894 1.79l-2 1a1 1 0 0 1 -1.342-.448zm-.11 3.548a1 1 0 0 1 1-1h2a1 1 0 0 1 0 2h-2a1 1 0 0 1 -1-1z"
              />
            </svg>
          </span>
          <span>{{ $t('sidebar.call-campaign.title') }}</span>
        </span>
        <sidebar-link :to="{ name: 'call-campaign-flows-list' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M24,4c0-2.206-1.794-4-4-4s-4,1.794-4,4c0,1.86,1.277,3.428,3,3.873v.127c0,1.654-1.346,3-3,3h-1c-1.193,0-2.267,.525-3,1.357-.733-.832-1.807-1.357-3-1.357h-1c-1.654,0-3-1.346-3-3v-.127c1.723-.445,3-2.013,3-3.873C8,1.794,6.206,0,4,0S0,1.794,0,4c0,1.86,1.277,3.428,3,3.873v.127c0,2.757,2.243,5,5,5h1c1.103,0,2,.897,2,2v1.127c-1.723,.445-3,2.013-3,3.873,0,2.206,1.794,4,4,4s4-1.794,4-4c0-1.86-1.277-3.428-3-3.873v-1.127c0-1.103,.897-2,2-2h1c2.757,0,5-2.243,5-5v-.127c1.723-.445,3-2.013,3-3.873ZM2,4c0-1.103,.897-2,2-2s2,.897,2,2-.897,2-2,2-2-.897-2-2ZM14,20c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2,2,.897,2,2Zm6-14c-1.103,0-2-.897-2-2s.897-2,2-2,2,.897,2,2-.897,2-2,2Z"
                />
              </svg>
            </span>
            <span>{{ $t('sidebar.call-campaign.flows') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'call-campaign-calls' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M13,1a1,1,0,0,1,1-1A10.011,10.011,0,0,1,24,10a1,1,0,0,1-2,0,8.009,8.009,0,0,0-8-8A1,1,0,0,1,13,1Zm1,5a4,4,0,0,1,4,4,1,1,0,0,0,2,0,6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2Zm9.093,10.739a3.1,3.1,0,0,1,0,4.378l-.91,1.049c-8.19,7.841-28.12-12.084-20.4-20.3l1.15-1A3.081,3.081,0,0,1,7.26.906c.031.031,1.884,2.438,1.884,2.438a3.1,3.1,0,0,1-.007,4.282L7.979,9.082a12.781,12.781,0,0,0,6.931,6.945l1.465-1.165a3.1,3.1,0,0,1,4.281-.006S23.062,16.708,23.093,16.739Zm-1.376,1.454s-2.393-1.841-2.424-1.872a1.1,1.1,0,0,0-1.549,0c-.027.028-2.044,1.635-2.044,1.635a1,1,0,0,1-.979.152A15.009,15.009,0,0,1,5.9,9.3a1,1,0,0,1,.145-1S7.652,6.282,7.679,6.256a1.1,1.1,0,0,0,0-1.549c-.031-.03-1.872-2.425-1.872-2.425a1.1,1.1,0,0,0-1.51.039l-1.15,1C-2.495,10.105,14.776,26.418,20.721,20.8l.911-1.05A1.121,1.121,0,0,0,21.717,18.193Z"
                />
              </svg>
            </span>
            <span>{{ $t('sidebar.call-campaign.calls') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
      <sidebar-link-group>
        <span slot="title" class="sidebar-menu-item-title">
          <span class="sidebar-menu-item-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M8.50178 5.38707C8.80966 5.10997 8.83462 4.63576 8.55752 4.32787C8.28043 4.01999 7.80621 3.99503 7.49833 4.27213L5.76084 5.83587C5.0245 6.49853 4.41369 7.04822 3.99428 7.54679C3.55325 8.07104 3.23975 8.6343 3.23975 9.3296C3.23975 10.0249 3.55325 10.5882 3.99428 11.1124C4.41369 11.611 5.02449 12.1607 5.76083 12.8233L7.49833 14.3871C7.80621 14.6642 8.28043 14.6392 8.55752 14.3313C8.83462 14.0234 8.80966 13.5492 8.50178 13.2721L6.80531 11.7453C6.01743 11.0362 5.48623 10.5558 5.14213 10.1468C4.81188 9.7542 4.73975 9.52502 4.73975 9.3296C4.73975 9.13417 4.81188 8.90499 5.14213 8.51241C5.48623 8.10338 6.01743 7.62298 6.80531 6.91389L8.50178 5.38707Z"
              />
              <path
                d="M14.1795 4.27517C14.5798 4.38157 14.818 4.79234 14.7117 5.19266L10.7248 20.1927C10.6184 20.593 10.2077 20.8312 9.80735 20.7248C9.40703 20.6184 9.16877 20.2077 9.27517 19.8074L13.262 4.80735C13.3684 4.40704 13.7792 4.16877 14.1795 4.27517Z"
              />
              <path
                d="M15.4425 10.4983C15.7196 10.1904 16.1938 10.1654 16.5017 10.4425L18.2392 12.0063C18.9756 12.6689 19.5864 13.2186 20.0058 13.7172C20.4468 14.2415 20.7603 14.8047 20.7603 15.5C20.7603 16.1953 20.4468 16.7586 20.0058 17.2828C19.5864 17.7814 18.9756 18.3311 18.2392 18.9937L16.5017 20.5575C16.1938 20.8346 15.7196 20.8096 15.4425 20.5017C15.1654 20.1938 15.1904 19.7196 15.4983 19.4425L17.1947 17.9157C17.9826 17.2066 18.5138 16.7262 18.8579 16.3172C19.1882 15.9246 19.2603 15.6954 19.2603 15.5C19.2603 15.3046 19.1882 15.0754 18.8579 14.6828C18.5138 14.2738 17.9826 13.7934 17.1947 13.0843L15.4983 11.5575C15.1904 11.2804 15.1654 10.8062 15.4425 10.4983Z"
              />
            </svg>
          </span>
          <span>{{ $t('sidebar.developers-tab.title') }}</span>
        </span>
        <sidebar-link
          :to="{ name: 'webrtc-credentials' }"
          v-if="currentTenant.hasTenant"
        >
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.9771 14.7904C21.6743 12.0932 21.6743 7.72013 18.9771 5.02291C16.2799 2.3257 11.9068 2.3257 9.20961 5.02291C7.41866 6.81385 6.8169 9.34366 7.40432 11.6311C7.49906 12 7.41492 12.399 7.14558 12.6684L3.43349 16.3804C3.11558 16.6984 2.95941 17.1435 3.00906 17.5904L3.24113 19.679C3.26587 19.9017 3.36566 20.1093 3.52408 20.2677L3.73229 20.4759C3.89072 20.6343 4.09834 20.7341 4.32101 20.7589L6.4096 20.9909C6.85645 21.0406 7.30164 20.8844 7.61956 20.5665L8.32958 19.8565L6.58343 18.1294C6.28893 17.8382 6.28632 17.3633 6.5776 17.0688C6.86888 16.7743 7.34375 16.7717 7.63825 17.063L9.39026 18.7958L11.3319 16.8541C11.6013 16.5848 12 16.5009 12.3689 16.5957C14.6563 17.1831 17.1861 16.5813 18.9771 14.7904ZM12.5858 8.58579C13.3668 7.80474 14.6332 7.80474 15.4142 8.58579C16.1953 9.36684 16.1953 10.6332 15.4142 11.4142C14.6332 12.1953 13.3668 12.1953 12.5858 11.4142C11.8047 10.6332 11.8047 9.36684 12.5858 8.58579Z"
                />
              </svg>
            </span>
            <span>{{ $t('sidebar.developers-tab.webrtc-credentials') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link :to="{ name: 'api-credentials' }">
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M6.36997 9.50993C2.28997 9.79993 2.29997 15.7099 6.36997 15.9999H16.03C17.2 16.0099 18.33 15.5699 19.2 14.7799C22.06 12.2799 20.53 7.27991 16.76 6.79991C15.41 -1.34009 3.61998 1.74993 6.40998 9.50993"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>{{ $t('sidebar.developers-tab.api-credentials') }}</span>
          </span>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'zendesk-credentials' }"
          v-if="currentTenant.hasTenant"
        >
          <span slot="title" class="sidebar-menu-item-title">
            <span class="sidebar-menu-item-image">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="18px"
                viewBox="0 0 23 18"
                version="1.1"
              >
                <g id="surface1">
                  <path
                    d="M 10.625 4.753906 L 10.625 18 L 0 18 Z M 10.625 0 C 10.625 3.027344 8.25 5.480469 5.316406 5.480469 C 2.386719 5.480469 0 3.027344 0 0 Z M 12.375 18 C 12.375 14.972656 14.75 12.519531 17.683594 12.519531 C 20.613281 12.519531 22.988281 14.972656 22.988281 18 Z M 12.375 13.246094 L 12.375 0 L 23 0 Z M 12.375 13.246094 "
                  />
                </g>
              </svg>
            </span>
            <span>{{ $t('sidebar.developers-tab.zendesk-credentials') }}</span>
          </span>
        </sidebar-link>
      </sidebar-link-group>
    </template>
  </vuestic-sidebar>
</template>

<script>
import VuesticSidebar from '../../../vuestic-theme/vuestic-components/vuestic-sidebar/VuesticSidebar'
import SidebarLink from './components/SidebarLink'
import SidebarLinkGroup from './components/SidebarLinkGroup'
import { mapGetters } from 'vuex'
export default {
  name: 'app-sidebar',
  components: {
    VuesticSidebar,
    SidebarLink,
    SidebarLinkGroup
  },
  data() {
    return {
      canCreate: false
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters(['currentTenant', 'pbxUrl'])
  }
}
</script>
<style lang="scss">
.sidebar-link {
  a {
    .sidebar-menu-item-title {
      display: flex;
      align-items: center;
      .sidebar-menu-item-image {
        width: 30px;
        text-align: center;
        @media (max-width: 1368px) {
          width: 24px;
        }
        svg {
          fill: $blue;
          path[stroke-width='2'] {
            stroke: $blue;
          }
          @media (max-width: 1368px) {
            width: 15.51px;
          }
        }
        i {
          color: $blue;
        }
      }
      .sidebar-menu-item-text {
        margin: 0 10px;
      }
    }
    &.router-link-exact-active,
    &:hover {
      .sidebar-menu-item-title {
        .sidebar-menu-item-image {
          svg {
            fill: $white;
            path[stroke-width='2'] {
              stroke: $white;
            }
          }
          i {
            color: $white;
          }
        }
      }
    }
  }
  .icon {
    width: 24px;
  }
}
</style>
