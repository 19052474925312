import { name } from 'jssip'
import UserLayout from '../../layouts/UserLayout.vue'
const EmptyParentComponent = {
  template: '<router-view></router-view>'
}
const userRoutes = [
  {
    path: '/',
    component: UserLayout,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import(`../../pages/user/Dashboard.vue`)
      },
      // extensions
      {
        path: 'extensions',
        name: 'all-extensions',
        component: () => import(`../../components/extensions/extensions.vue`)
      },
      {
        name: 'monitor-extensions',
        path: 'extensions/monitor',
        component: () => import(`../../pages/user/extensions/monitor.vue`)
      },
      {
        name: 'extension-create',
        path: 'extensions/create',
        component: () => import('../../pages/user/extensions/create.vue')
      },
      {
        name: 'extension-update',
        path: 'extensions/update/:id',
        component: () => import('../../pages/user/extensions/update.vue')
      },
      // call reporting
      {
        name: 'call-reporting',
        path: 'call-reporting',
        component: () => import('../../components/reporting/CallReporting.vue')
      },
      // billing
      {
        name: 'billing-rates',
        path: 'rates',
        component: () => import('../../components/billing/Rates.vue')
      },
      {
        name: 'billing-refill',
        path: 'refill',
        component: () => import('../../components/billing/Refill.vue')
      },
      {
        name: 'billing-payments',
        path: 'payments',
        component: () => import('../../components/billing/Payments.vue')
      },
      {
        name: 'billing-charges',
        path: 'charges',
        component: () => import('../../components/billing/Charges.vue')
      },
      {
        name: 'billing-invoices',
        path: 'invoices',
        component: () => import('../../components/billing/Invoices.vue')
      },

      {
        name: 'usage',
        path: 'usage',
        component: EmptyParentComponent,
        children: [
          {
            name: 'usage-summary',
            path: 'summary',
            component: () => import('../../pages/user/usage/UsageSummary.vue')
          },
          {
            name: 'usage-detailed',
            path: 'detailed',
            component: () => import('../../pages/user/usage/UsageDetailed.vue')
          }
        ]
      },
      {
        name: 'order-confirmation',
        path: 'order-confirmation',
        component: EmptyParentComponent,
        children: [
          // flows
          {
            name: 'order-confirmation-flows-list',
            path: 'flows',
            component: () =>
              import('../../components/order-confirmation/flows.vue')
          },
          {
            name: 'order-confirmation-create-flow',
            path: 'flows/create',
            component: () =>
              import('../../components/order-confirmation/create-flow.vue')
          },
          {
            name: 'order-confirmation-edit-flow',
            path: 'flows/edit/:flowId',
            component: () =>
              import('../../components/order-confirmation/edit-flow.vue')
          },
          // calls
          {
            name: 'order-confirmation-calls',
            path: 'calls',
            component: () =>
              import('../../components/order-confirmation/calls.vue')
          },
          {
            name: 'order-confirmation-excel-calls-list',
            path: 'calls/excel/list',
            component: () =>
              import('../../components/order-confirmation/excel-calls-list.vue')
          },
          {
            name: 'order-confirmation-excel-call-file',
            path: 'excel/:id',
            component: () =>
              import('../../components/order-confirmation/excel-call-file.vue')
          },
          {
            name: 'order-confirmation-calls-list',
            path: 'calls/:type/list',
            component: () =>
              import('../../components/order-confirmation/calls-list.vue')
          }
        ]
      },
      {
        name: 'call-campaign',
        path: 'call-campaign',
        component: EmptyParentComponent,
        children: [
          // flows
          {
            name: 'call-campaign-flows-list',
            path: 'flows',
            component: () => import('../../components/call-campaign/flows.vue')
          },
          {
            name: 'call-campaign-create-flow',
            path: 'flows/create',
            component: () =>
              import('../../components/call-campaign/create-flow.vue')
          },
          {
            name: 'call-campaign-edit-flow',
            path: 'flows/edit/:flowId',
            component: () =>
              import('../../components/call-campaign/edit-flow.vue')
          },
          // calls
          {
            name: 'call-campaign-calls',
            path: 'calls',
            component: () => import('../../components/call-campaign/calls.vue')
          },
          {
            name: 'call-campaign-excel-calls-list',
            path: 'calls/excel/list',
            component: () =>
              import('../../components/call-campaign/excel-calls-list.vue')
          },
          {
            name: 'call-campaign-excel-call-file',
            path: 'excel/:id',
            component: () =>
              import('../../components/call-campaign/excel-call-file.vue')
          },
          {
            name: 'call-campaign-calls-list',
            path: 'calls/:type/list',
            component: () =>
              import('../../components/call-campaign/calls-list.vue')
          }
        ]
      },
      // agents
      {
        name: 'all-agents',
        path: 'agents',
        component: () => import(`../../pages/user/agents/agents.vue`)
      },
      {
        name: 'agent-create',
        path: 'agents/create',
        component: () => import('../../pages/user/agents/create.vue')
      },
      {
        name: 'agent-update',
        path: 'agents/:id/update',
        component: () => import('../../pages/user/agents/update.vue')
      },
      {
        name: 'inno-support',
        path: 'inno-support',
        component: () => import('../../components/support/InnoSupport.vue')
      },
      {
        name: 'all-settings',
        path: 'settings',
        component: EmptyParentComponent,
        children: [
          {
            name: 'settings',
            path: '',
            component: () => import('../../pages/user/settings/Settings.vue')
          },
          {
            name: 'change-password',
            path: 'change-password',
            component: () =>
              import('../../pages/user/settings/ChangePassword.vue')
          },
          {
            name: 'update-organization-info',
            path: 'update-organization-info',
            component: () =>
              import('../../pages/user/settings/UpdateOrganizationInfo.vue')
          }
        ]
      },
      {
        name: 'all-tags',
        path: 'tags',
        component: EmptyParentComponent,
        children: [
          // tags crud
          {
            name: 'tags',
            path: '',
            component: () => import('../../pages/user/tags/Tags.vue')
          }
        ]
      },
      {
        name: 'all-developers-tab',
        path: 'developers',
        component: EmptyParentComponent,
        children: [
          {
            name: 'all-webrtc-credentials',
            path: 'webrtc-credentials',
            component: EmptyParentComponent,
            children: [
              {
                name: 'webrtc-credentials',
                path: '',
                component: () =>
                  import(
                    '../../pages/user/developers/webrtc-credentials/List.vue'
                  )
              },
              {
                name: 'create-webrtc-credentials',
                path: 'create',
                component: () =>
                  import(
                    '../../pages/user/developers/webrtc-credentials/Create.vue'
                  )
              }
            ]
          },
          {
            name: 'all-zendesk-credentials',
            path: 'zendesk-credentials',
            component: EmptyParentComponent,
            children: [
              {
                name: 'zendesk-credentials',
                path: '',
                component: () =>
                  import(
                    '../../pages/user/developers/zendesk-credentials/List.vue'
                  )
              },
              {
                name: 'create-zendesk-credentials',
                path: 'create',
                component: () =>
                  import(
                    '../../pages/user/developers/zendesk-credentials/Create.vue'
                  )
              }
            ]
          },
          {
            name: 'all-api-credentials',
            path: 'api-credentials',
            component: EmptyParentComponent,
            children: [
              {
                name: 'api-credentials',
                path: '',
                component: () =>
                  import('../../pages/user/developers/api-credentials/List.vue')
              },
              {
                name: 'create-api-credentials',
                path: 'create',
                component: () =>
                  import(
                    '../../pages/user/developers/api-credentials/Create.vue'
                  )
              }
            ]
          }
        ]
      },
      {
        name: 'all-dids',
        path: 'dids',
        component: EmptyParentComponent,
        children: [
          {
            name: 'dids',
            path: '',
            component: () => import('../../pages/user/dids/Dids.vue')
          }
        ]
      }
    ],
    meta: { requiresAuth: true, userType: 'user' }
  }
]

export default userRoutes
